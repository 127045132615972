import { render, staticRenderFns } from "./DishCardList.vue?vue&type=template&id=8c6557ba&scoped=true&"
import script from "./DishCardList.vue?vue&type=script&lang=js&"
export * from "./DishCardList.vue?vue&type=script&lang=js&"
import style0 from "./DishCardList.vue?vue&type=style&index=0&id=8c6557ba&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c6557ba",
  null
  
)

export default component.exports